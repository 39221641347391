<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <div class="btn btn-primary" @click="get">Kliknij mnie</div>
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

import MailAdressService from "@/services/mailAdress.service.js";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  methods: {
    get() {
      new MailAdressService()
        .get()
        .then(resp => {
          console.log(resp);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
