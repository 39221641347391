import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./assets/css/animate.css";
import "./assets/css/font-awesome.css";
import "./assets/css/toastr.min.css";
import "./assets/css/bootstrap.css";
import "./assets/css/data-tables.css";
import "./assets/css/bootstrap-datetimepicker.css";
import "./assets/css/style.css";
import "./assets/css/custom.scss";

// MSLA
import msal from "vue-msal";

// SENTRY
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

// Context menu
import VueContext from "vue-context";
import "../node_modules/vue-context/dist/css/vue-context.css";

import ApiService from "./services/api.service";

import moment from "moment";
import toastr from "toastr";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.config.performance = true;

Sentry.init({
  dsn:
    "https://6b179e76758c43478ff12dbf9dc832fe@o441536.ingest.sentry.io/5537742",
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true
    }),
    new Integrations.BrowserTracing()
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

toastr.options = {
  closeButton: true,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  positionClass: "toast-top-right",
  onclick: null,
  showDuration: "400",
  hideDuration: "1000",
  timeOut: "7000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};

Vue.prototype.$moment = moment;
Vue.prototype.$toastr = toastr;

Vue.use(msal, {
  auth: {
    clientId: "2ee8189c-a500-4a0a-9cc8-67cbefeb32cf",
    authority:
      "https://login.microsoftonline.com/7fe5fcb9-8b91-49f5-95a8-6014f804d2e6/",
    redirectUri:
      process.env.NODE_ENV === "production"
        ? "https://bts.cosmogroup.pl/"
        : "http://localhost:81"
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      profile:
        "/me?$select=department,id,mail,userPrincipalName,displayName,jobTitle",
      // photo: { url: '/me/photo/$value', responseType: 'blob', force: true }
      messages: "/me/messages"
    }
  }
});

Vue.component("vue-context", VueContext);

Vue.mixin({
  data() {
    return {
      optionsDate: {
        format: "YYYY-MM-DD",
        useCurrent: false
      },
      optionsWithTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false
      }
    };
  },
  methods: {
    onClick(text, data) {
      alert(`You clicked "${text}"!`);
      console.log(data);
      // => { foo: 'bar' }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App),

  created() {
    console.log("created");
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
    this.$msal.acquireToken().then(result => {
      console.log(result);
      ApiService.setHeader(result.accessToken);
    });
    if (process.env.NODE_ENV === "production") {
      ApiService.init("https://bts.cosmogroup.pl/api/");
    } else {
      ApiService.init("http://127.0.0.1:8848/api");
    }
  },

  beforeRouteEnter(to, from, next) {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
    console.log("beforeRouteEnter", to, from);
    next();
  },

  i18n,

  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate", to, from);
    next();
  }
}).$mount("#app");
