<template>
  <div id="wrapper">
    <div id="page-wrapper" class="gray-bg" v-if="$msal.isAuthenticated()">
      <div class="row border-bottom white-bg">
        <navbar />
      </div>
      <div class="wrapper py-4 px-0">
        <router-view />
      </div>
      <div class="footer">
        <div class="float-right">10GB of <strong>250GB</strong> Free.</div>
        <div>
          <strong>Copyright</strong> Cosmo Group Sp. z o.o. Sp. K. -
          {{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
    <div class="gray-bg" v-else style="weight: 100%;min-height:100vh;">
      <div class="spiner-app">
        <div class="sk-spinner sk-spinner-chasing-dots">
          <div class="sk-dot1"></div>
          <div class="sk-dot2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/base/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar
  }
};
</script>

<style scoped>
.spiner-app {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
