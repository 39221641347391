import TokenService from "./storage.service";
import ApiService from "./api.service";

export default class MainService {
  token_service = {};
  api_service = {};

  constructor() {
    this.token_service = new TokenService();
    this.api_service = ApiService;
  }
}
