import MainService from "./main.service";

export default class MailAdress extends MainService {
  constructor() {
    super();
  }

  async get($skiptoken) {
    try {
      const url = $skiptoken != "" ? `msal?$skiptoken=${$skiptoken}` : "msal";
      const resp = await this.api_service.get(url);
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async post(data) {
    try {
      const resp = await this.api_service.post("msal", data);
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }
}
