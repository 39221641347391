export default class TokenService {
  token_key = "accessToken";
  refresh_token = "refreshToken";

  getToken() {
    return localStorage.getItem(this.token_key);
  }

  setToken(accessToken) {
    localStorage.setItem(this.token_key, accessToken);
  }

  removeToken() {
    localStorage.removeItem(this.token_key);
  }

  getRefreshToken() {
    return localStorage.getItem(this.refresh_token);
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem(this.refresh_token, refreshToken);
  }

  removeRefreshToken() {
    localStorage.removeItem(this.refresh_token);
  }
}
