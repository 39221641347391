// import TokenService from "./storage.service";
import axios from "axios";

class ApiService {
  init(baseUrl) {
    axios.defaults.baseURL = baseUrl;
  }

  setHeader(msal) {
    axios.defaults.headers.common.Authorization = `Bearer ${msal}`;
  }

  removeHeader() {
    axios.defaults.headers.common = {};
  }

  get(url) {
    return axios.get(url);
  }

  post(url, data) {
    return axios.post(url, data);
  }

  put(url, data) {
    return axios.put(url, data);
  }

  delete(url, data) {
    return axios.delete(url, data);
  }

  customRequest(data) {
    return axios(data);
  }
}

export default new ApiService();
