import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/mailbox",
    name: "MailboxView",
    component: () => import("../views/Mailbox/Wrapper.vue"),
    children: [
      {
        path: ":id",
        name: "Mailbox",
        component: () => import("../views/Mailbox/FolderWrapper.vue"),
        children: [
          {
            path: "folder/:folder",
            name: "Mailbox",
            component: () => import("../views/Mailbox.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/mail-adress-list",
    name: "MailAdressListView",
    component: () => import("../views/Settings/MailAdressList.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes
});

export default router;
