<template>
  <nav class="navbar navbar-expand-lg navbar-static-top" role="navigation">
    <a href="#" class="navbar-brand">COSMO</a>

    <div class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav mr-auto">
        <router-link to="/dashboard" tag="li">
          <a>
            {{ $t("dashboard") }}
          </a>
        </router-link>
        <router-link to="/mailbox" tag="li">
          <a>
            {{ $t("mailbox") }}
          </a>
        </router-link>
        <router-link to="/mail-adress-list" tag="li">
          <a>
            {{ $t("mail_addresses") }}
          </a>
        </router-link>
      </ul>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <a href="#" @clikc.prevent="$msal.signOut()">
            <i class="fa fa-sign-out"></i> Log out
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { msalMixin } from "vue-msal";

export default {
  name: "Navbar",
  mixins: [msalMixin],
  computed: {
    user() {
      let user = null;
      if (this.msal.isAuthenticated) {
        // Note that the dollar sign ($) is missing from this.msal
        user = {
          ...this.msal.user,
          profile: {}
        };
        if (this.msal.graph && this.msal.graph.profile) {
          user.profile = this.msal.graph.profile;
        }
      }
      return user;
    }
  }
};
</script>
